@tailwind base;
@tailwind components;
@tailwind utilities;

.hideMenuNav {
  display: none;
}

.showMenuNav {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 8px;
  height: 100vh;
  background-color: #00000090;
  height: 100%;
  position: fixed;
}
