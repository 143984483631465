@import url('https://fonts.cdnfonts.com/css/avenir');

body {
  /* margin: 0; */
  font-family: 'Avenir', sans-serif;                                     
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #F1F1F1; */
  background-color: #f4f4f4;
}

.pattern{
  background-image: url('../src/Images/Pattern.jpg');
  mix-blend-mode: multiply;
  background-size: cover;
  height: 150px;
}

.background {
  background-image: url(../src/Images/zippy.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.bg-mesh {
  background-image: url(../src/Images/Mesh.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.noor-bg {
  background-image: url(../src/Images/noor.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-shopping{
  background-image: url(../src/Images/shopping.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-invoice{
  background-image: url(../src/Images/invoice.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-pos1{
  background-image: url(../src/Images/pos1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.bg-pos2{
  background-image: url(../src/Images/pos2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.bg-pos3{
  background-image: url(../src/Images/pos3.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.bg-bag{
  background-image: url(../src/Images/bag.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
 
#navbar.sticky {
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.slideshow-container {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.slide {
  display: none;
  height: 300px; /* Adjust height as needed */
  background-size: cover;
  background-position: center;
  transition: opacity 1s ease;
}

.slide.active {
  display: block;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
}

.slide1 {
  background-image: url('./Images/header1.png');
}

.slide2 {
  background-image: url('./Images/header2.png');
}

.slide3 {
  background-image: url('./Images/header3.png');
}